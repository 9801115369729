import http from "@/utils/request";

// post
export function getCompany(data) {
  return http({
    method: "get",
    url: "/IntentionCust/callTYC.nd",
    params:data
  });
}
export function apply() {
  return http({
    method: "get",
    url: "/IntentionCust/apply.htm?source=b2b&&loginType=CS",

  });
}

// 获取城市接口
export function getCity(data) {
  return http({
    url: 'IntentionCust/getCity.nd',
    method: 'post',
    data
  })
}
// proviceId: 16074


export function townChange(data) {
  return http({
    url: '/IntentionCust/getDistrict.nd',
    method: 'post',
    data
  })
}
export function getQu(data) {
  return http({
    url: 'IntentionCust/getTown.nd',
    method: 'post',
    data
  })
}
export function getMessageCode(data) {
  return http({
    url: '/IntentionCust/sendSecurityCode.nd',
    method: 'post',
    data
  })
}
//  验证短信验证码和手机号是否正确
export function isRepeatData(data) {
  return http({
    url: '/IntentionCust/isRepeatData.nd',
    method: 'post',
    data
  })
}

//tijiao
//
export function sumbitForm(data) {
  return http({
    url: 'IntentionCust/saveIntentionCustApply.nd',
    method: 'post',
    data
  })
}