import { apply, getCity, getCompany, getQu, townChange, getMessageCode, isRepeatData, sumbitForm } from "./api";

export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: "Intend_rule" }),
      fromData: {
        company: "",// 公司名称
        taxpayerIdentificationNumber: "", // 纳税人识别号
        companyType: "",

        availableFunds: 0,// 可用资金
        netWork: "",// 是否有网络
        peopleNum: 0,// 员工数量
        peopleName: "",// 联系人姓名
        peoplePhone: "",// 联系人电话
        verificationCode: ""
      },
      provinceCode: "",// 省
      cityCode: "",// 市
      districtCode: "",// 区
      townCode: "",// 县
      provices: [],
      cityData: [],
      getTown: [],
      qu: [],
      userDetailAddress: "",// 详细地址
      companyList: [],// 公司列表
      productList: [], //  合作产品列表
      FXDLlnum: "",
      fetching: false,
      product: [],
      tableData: [],// 选中表格数据
      columns: [
        {
          title: "合作产品",
          dataIndex: "matklName",
          width: 100
        },
        {
          title: "营销模式",
          dataIndex: "type",
          scopedSlots: { customRender: "type" }
          ,
          width: 100
        },
        {
          title: "预计年度销售规模（万）",
          dataIndex: "num",
          scopedSlots: { customRender: "num" },
          width: 100
        }


      ],
      orderDis: [
        {
          key: "17452",
          value: "直营"
        },
        {
          key: "17451",
          value: "分销"
        },
        {
          key: "17453",
          value: "代理"
        }
      ],
      codevalue: "获取手机验证码",
      clickCancle: false,
      isLoading: false,
      messageModal: false
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    sumbitApply() {

      this.isLoading = true;
      // 先判断所有填写的是否填写 是的话在验证短信验证是否正确
      // 需要先验证短信验证码是否正确
      if (!this.fromData.company) {
        this.$message.warning("请选择或输入公司名称");
        this.isLoading = false;
        return;
      }
      if (!this.fromData.taxpayerIdentificationNumber) {
        this.$message.warning("请录入公司纳税人识别号", 3);
        this.isLoading = false;
        return;
      }
      if (!this.fromData.companyType) {
        this.$message.warning("请选择公司类型", 3);
        this.isLoading = false;
        return;
      }
      if (!this.fromData.peopleNum) {
        this.$message.warning("请填写员工数量", 3);
        this.isLoading = false;
        return;
      }
      if (!this.fromData.peopleName) {
        this.$message.warning("请填写联系人姓名", 3);
        this.isLoading = false;
        return;
      }
      if (!this.fromData.peoplePhone) {
        this.$message.warning("请填写联系人电话", 3);
        this.isLoading = false;
        return;
      }
      if (!this.provinceCode) {
        this.$message.warning("请选择省", 3);
        this.isLoading = false;
        return;
      }
      if (!this.cityCode) {
        this.$message.warning("请选择市", 3);
        this.isLoading = false;
        return;
      }
      if (!this.districtCode) {
        this.$message.warning("请选择区", 3);
        this.isLoading = false;
        return;
      }
      if (!this.townCode) {
        this.$message.warning("请选择县", 3);
        this.isLoading = false;
        return;
      }
      if (!this.userDetailAddress) {
        this.$message.warning("请输入详细地址", 3);
        this.isLoading = false;
        return;
      }
      if (!this.fromData.availableFunds) {
        this.$message.warning("请输入可用资金", 3);
        this.isLoading = false;
        return;
      }
      if (!this.fromData.netWork) {
        this.$message.warning("请选择是否有网络", 3);
        this.isLoading = false;
        return;
      }
      if (this.product.length <= 0) {
        this.$message.warning("请选择合作产品", 3);
        this.isLoading = false;
        return;
      }
      let a = 0;
      this.tableData.forEach(item => {
        if (!item.money || !item.keyValue) {
          a = a + 1;
        }
      });
      if (a > 0) {
        this.$message.warning("申请合作细节中数据不完整，请重新填写");
        this.isLoading = false;
        return;
      }
      if (!this.fromData.peoplePhone) {
        this.$message.warning("请输入手机号");
        this.isLoading = false;
        return;
      }
      if (!this.fromData.verificationCode) {
        this.$message.warning("请输入短信验证码");
        this.isLoading = false;
        return;
      }

      let data = {
        companyFullName: this.fromData.company,
        securityCode: this.fromData.verificationCode,
        contactPhone: this.fromData.peoplePhone
      };
      isRepeatData(data).then(res => {
        if (res.data == "errorSecurityCode") {
          this.$message.warning("短信验证码错误", 3);
          return;
        } else if (res.data == 1) {
          this.messageModal = true;
          return;
        } else if (res.data == -1) {
          this.$message.warning("该商家已有申请被受理，不能发起此次申请", 3);
          return;
        } else {
          this.changeCherk();
        }
      });

      this.isLoading = false;
    },
    // 点击提交先校验手机号是否提交过申请 如果提交过以后点击确定触发该方法  再次提交 取消不进行操作
    changeCherk() {

      let filterList = "";
      this.tableData.forEach(item => {
        filterList += (item.matklName + "-" + item.id + "-" + item.keyValue + "-" + item.money + ",");
      });
      let data = {
        r_companyFullName: this.fromData.company, //公司名称
        r_taxCode: this.fromData.taxpayerIdentificationNumber, //税号
        r_companyType: this.fromData.companyType, // 公司类型
        r_staffNumber: this.fromData.peopleNum, // 员工数量
        r_contactName: this.fromData.peopleName, //联系人姓名
        r_province: this.provinceCode,
        r_city: this.cityCode,
        r_county: this.districtCode,
        r_towns: this.townCode,
        r_detailedAddress: this.userDetailAddress, //详细地址
        r_availableCapital: this.fromData.availableFunds, //可用资金
        r_haveSellingNetwork: this.fromData.netWork, //是否有网络
        r_custinfoDetails: filterList, //营销模式 金额 合作产品
        r_contactPhone: this.fromData.peoplePhone //联系人电话
      };
      sumbitForm(data).then(res => {
        if (res.data.code == "success") {
          // contact: "北京营销中心"
          // custId: "7C7E747B7E7D747F7C7A7F
          // contact: "陈为敏-+86-15588667696"
          // custId: "7C7E747B7E7D747F7C7A7A"
          this.$router.push({
            path: "/IntendedMerchantSuccess",
            query: {
              custId: res.data.data.custId,
              contact: res.data.data.contact
            }
          });
        } else {
          this.$message.warning(res.data.msg, 3);
        }
      });
    },
    goBack() {
      window.close();
      this.$router.push({ path: "/index" });
    },
    // 获取手机验证码
    getCode() {
      var totaltime = 60;
      this.clickCancle = true;
      var times = setInterval(() => {
        totaltime--;
        this.codevalue = "已发送" + totaltime + "s";
        if (totaltime == 0) {
          clearInterval(times);
          this.codevalue = "获取验证码";
          this.clickCancle = false;
        }
        ;
      }, 1000);
    },
    getCodeN() {
      let data = {
        contactPhone: this.fromData.peoplePhone
      };
      if (!this.fromData.peoplePhone) {
        this.$message.warning("请输入联系人电话");
        return;
      }
      getMessageCode(data).then(res => {
        if (res.data == true) {
          this.$message.success("发送成功");
          this.getCode();
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    getInfo() {
      apply().then(res => {
        this.productList = res.data.baseMatklList;
        this.provices = res.data.provicesList;
      });

    },
    /**/
    // 省 选择
    handleProvinceChange() {
      this.choseCity(this.provinceCode);
      this.cityCode = "";
      this.districtCode = "";
      this.townCode = "";
    },
    // 获取城市
    choseCity(proviceCode) {
      this.cityData = [];
      this.getTown = [];
      this.qu = [];
      if (!proviceCode) {
        return;
      }
      let data = {
        proviceId: proviceCode
      };
      getCity(data).then(res => {
        this.cityData = res.data;
        //获取城镇区
        this.getTowns(this.cityCode);
      }).catch(err => {
        console.log(err);
      });

    },
    // 获取城镇区
    getTowns(cityCode) {
      this.getTown = [];
      this.qu = [];
      if (!cityCode) {
        return;
      }
      let data = {
        cityId: cityCode
      };
      townChange(data).then(res => {
        this.getTown = res.data;
        this.getqu(this.districtCode);
      }).catch(err => {
        console.log(err);
      });

    },
    // 获取城镇下一家
    getqu(districtCode) {
      this.qu = [];
      if (!districtCode) {
        return;
      }


      let data = {
        districtId: districtCode
      };
      getQu(data).then(res => {
        if (res.data.length > 0) {
          this.townCode = res.data[0].id;
        }

        this.qu = res.data;
      })
        .catch(err => {
          console.log(err);
        });

    },
    // 城市选择
    handleCityChange() {
      this.districtCode = "";
      this.townCode = "";
      this.getTowns(this.cityCode);
    },
    // 城镇选择
    handleTownChange() {
      this.townCode = "";
      this.getqu(this.districtCode);
    },
    // 城镇下一级 选择
    handleZhenChange() {
    },
    /**/
    //更改公司名称的时候触发 没有用 以后要删除 只是为了获取数据是否正确
    // changeCompany(e) {
    // },
    // 更改纳税人识别号
    changeIdentificationNumber(e) {
    },
    changePeopleNum(value) {
    },
    changePeopleName(e) {
    },
    changePeoplePhone(e) {
    },
    // 可用资金(万元)
    changeavailableFunds(value) {
    },
    changeCompany(value, e) {
      this.fromData.company = value;
    },

    getCompanys(value) {
      this.fromData.company = value;
      let data = {
        word: this.fromData.company
      };
      this.fetching = true;
      getCompany(data).then(res => {
        if (res.data.result.items.length > 0 && res.data.result.items !== null) {
          this.companyList = [...new Set(res.data.result.items)]; //缓存列表数据，前端分页
        }

        this.fetching = false;
      });

    },
    //查询数据
    search() {
    },
    // 更改公司类型
    companyTypeChange() {
    },
    // 是否有网络
    netWorkChange() {
    },
    // 更改合作产品
    changeProduct(e) {
      // this.productList.forEach(item => {
      //   this.product.forEach(proDuctItem => {
      //     if (item.matklCode === proDuctItem) {
      //       this.tableData.push(item);
      //     }
      //   });
      // });
      if (!this.tableData || this.tableData.length === 0) {
        e.forEach(code => {
          this.productList.forEach(item => {
            if (code === item.matklCode) {
              const data = item;
              data.keyValue = this.orderDis[0].key;
              this.tableData.push(data);
            }
          });
        });
      } else {
        const tableData = [];
        this.tableData.forEach(data => {
          if (e.some(code => data.matklCode === code)) {
            tableData.push(data);
          }
        });
        this.tableData = tableData;
        e.forEach(code => {
          this.productList.forEach(item => {
            if (code === item.matklCode && this.tableData.every(data => data.matklCode !== item.matklCode)) {
              const data = item;
              data.keyValue = this.orderDis[0].key;
              this.tableData.push(data);
            }
          });
        })
      }
    },
    changeaa(record) {

    },
    handleChangeshopList(item, index) {
      this.$forceUpdate();

    }
  }
};